import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { CircularProgress } from '@mui/material';

import { Layout, Flex } from '@components/layout';

import { useAuth, useEscala } from '@context';

const NewPlantao = loadable(() => import('@screens/escala_medico/NewPlantao'));

function Index({ escalaId, ...props }) {
  const auth = useAuth();
  const escala = useEscala();

  const [escalaData, setEscalaData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth?.user) {
      fetchEscala();
    }
  }, [auth]);

  const fetchEscala = async () => {
    const { getEscalaDoctorById } = escala;
    const result = await getEscalaDoctorById({ userId: auth.user.uid, escalaId });

    if (result) {
      const res = result.data();
      const grades = { ...res.grades };
      const g = {};

      Object.keys(grades).forEach((k) => {
        g[k] = { entrada: grades[k].entrada.toDate(), saida: grades[k].saida.toDate() };
      });

      setEscalaData({
        ...res,
        id: result.id,
        initialDate: res.initialDate.toDate(),
        grades: g,
      });

      setLoading(false);
    }
  };

  if (loading)
    return (
      <Layout key={0} {...props}>
        <Flex>
          <CircularProgress />
        </Flex>
      </Layout>
    );

  return (
    <Layout key={1} {...props}>
      <NewPlantao {...props} data={escalaData} />
    </Layout>
  );
}

export default Index;
